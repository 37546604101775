import React from 'react';
import VideoSection from 'components/common/VideoSection';

const video = {
  filename: 'videos/thumbnails/consulter-libido-mia-co.png',
  alt: '',
  video: 'https://www.youtube.com/embed/jU7QF6J3wOA',
  intro:
    '',
};

const VideoPL = () => <VideoSection {...video} />;

export default VideoPL;
