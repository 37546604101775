import React from 'react';
import Man1 from 'images/men/bulle1.svg';
import Man2 from 'images/men/bulle2.svg';
import Man3 from 'images/men/bulle3.svg';

export const testimonies = [
  {
    testimony:
      'Après plusieurs années avec mon copain, je n’avais plus du tout envie de lui et je me suis totalement bloquée. Je me disais que c’était normal et que la routine avait fait son affaire. Lorsque une amie m’a parlé du site, j’ai voulu y jeter un coup d\'œil. Quelques séances plus tard, j’ai mieux compris mon fonctionnement et je retrouve le chemin du désir. Merci.',
    patient: 'Aïcha',
    city: '',
    age: '27 ans',
    Icon: Man1,
    backgroundColorClass: 'lc-light-blue',
    flower: true,
    target: false,
  },
  {
    testimony:
      'J’ai subi une agression sexuelle il y a deux ans et je me retrouve paralysée depuis côté libido. Je n’ai jamais vraiment réussi à en parler, même à mes proches et quand j’ai découvert Mia.co, j’ai sauté le pas. La sexologue qui me suit est d’une gentillesse inouïe et surtout très professionnelle. J’avance à grands pas.',
    patient: 'Morgane',
    city: '',
    age: '24 ans',
    Icon: Man3,
    backgroundColorClass: 'lc-light-yellow',
    flower: false,
    target: true,
  },
  {
    testimony:
      '20 ans de relation avec ma femme, les enfants, le stress… les évènements de vie ont éteint petit à petit mon désir, même mon envie de plaisir en solo. C’était vraiment une situation inconfortable et j’avais vraiment l’envie d’avoir envie ! J’ai testé une sexologue dans ma ville mais ça n’a pas marché. Je suis maintenant suivie par une sexologue sur Mia.co et le changement opère déjà !',
    patient: 'Isabelle',
    city: '',
    age: '45 ans',
    Icon: Man2,
    backgroundColorClass: 'lc-light-cyan',
    flower: true,
    target: false,
  },
  {
    testimony:
      'Je ne comprenais pas pourquoi mon désir ne revenait pas après mon premier accouchement et ça devenait vraiment embêtant pour moi et pour mon partenaire. Après quelques consultations par téléphone, je renoue enfin avec mon envie sexuelle...',
    patient: 'Léa',
    city: '',
    age: '33 ans',
    Icon: Man2,
    backgroundColorClass: 'lc-light-cyan',
    flower: false,
    target: true,
  },
];

export const sections = [
  {
    title:
      'Quelle est la définition de la libido chez la femme ?\n',
    body: (
      <div>
        <p>
          On définit la libido selon deux types de désir sexuel : le désir sexuel « spontané »
          (pulsionnel) ou le désir sexuel « réactif » (qui réagit) à une stimulation.
        </p>
        <p>
          Le premier, le désir sexuel spontané, concernerait davantage les hommes ; alors que
          le désir sexuel réactif serait plutôt féminin.
        </p>
        <p>
          Il est généralement admis que les femmes sont plus sensibles à des stimuli complexes,
          en particulier de la représentation mentale qu’elles se font de leur partenaire
          (et du contexte), alors que les hommes seraient davantage attirés par des stimuli visuels.
        </p>
        <p>
          Même si évidemment, les types de stimuli qui déclenchent des réactions sexuelles sont
          variables d’une personne à l’autre.
        </p>
      </div>
    ),
  },
  {
    title:
      'Définition d’une baisse de libido.',
    body: (
      <div>
        <p>
          Quand une femme exprime une baisse de désir ou un trouble de l’excitation,
          il faut généralement distinguer si le désir sexuel est inhibé (absent), et
          se caractérise par une absence d’intérêt pour la sexualité, ou si le désir sexuel
          est « gêné » ou « freiné ».
        </p>
        <p>
          Dans la seconde hypothèse, la patiente continue d’avoir un intérêt pour la sexualité,
          mais son expression est bloquée par des éléments personnels, contextuels, relationnels,
          etc. Donc, le diagnostic médical prendra en compte toute altération de l’état de santé
          ou de bien-être, qui peut être à l’origine d‘un trouble de la libido.
        </p>
        <p>
          Ces derniers points devront être améliorés, afin d’améliorer la libido.
        </p>
      </div>
    ),
  },
  {
    title:
      'Quelles sont les causes d’une baisse de libido ?',
    body: (
      <div>
        <p>
          L’approche thérapeutique d’une baisse de libido repose principalement sur la prise
          en charge psycho-sexologique. Il existe en effet peu de médicaments de la libido
          féminine, et ils nécessitent encore une réflexion quant à leur intégration dans une
          prise en charge globale.
        </p>
        <p>
          Plusieurs traitements pharmacologiques sont en cours de développement, en plus de
          certains traitements spécifiques qui demandent encore d’être évalués.
        </p>
        <p>
          Le plus souvent, une information précise sur le fonctionnement sexuel féminin,
          un discours éclairant et rassurant sur l’origine conjoncturelle du désir sexuel,
          et le traitement des causes d’une baisse de libido, peuvent permettre d’améliorer
          significativement la situation.
        </p>
        <p>
          Un traitement pharmacologique peut être proposé en plus, comme aide transitoire.
          Une approche psycho-sexologique, comme nous l’avons dit, peut aussi être nécessaire
          chez certaines femmes. Enfin, les thérapies peuvent se dérouler individuellement
          ou en couple, afin de s’adapter à chaque patiente.
        </p>
      </div>
    ),
  },
  {
    title:
      'Quelle est la prise en charge thérapeutique d’une baisse de libido ?',
    body: (
      <div>
        <p>
          La libido féminine (et la réponse sexuelle féminine) implique de nombreux facteurs,
          la rendant mystérieuse et compliquée à comprendre.
        </p>
        <p>
          Toutes les études aujourd’hui soulignent la dépendance du désir sexuel des femmes
          à des facteurs neurobiologiques (estrogènes, prolactine, ocytocine, testostérone, etc.)
          et à des facteurs psycho-affectifs (personnels et/ou interpersonnels, contextuels, etc.).
        </p>
        <p>
          Le désir et la réponse sexuelle sont donc une combinaison d’intérêts, de motivation,
          d’émotions, de récompense et de mémoire. Tous ces facteurs s’intriquent ensemble et
          s’influencent, de sorte qu’ils ont tous un rôle à jouer dans le désir féminin.
        </p>
        <p>
          Donc, tout ce qui peut perturber cet équilibre délicat, peut perturber la libido
          féminine. Une baisse de libido peut par conséquent avoir une origine neurologique,
          biologique, relationnelle, psychologique ou encore environnementale…
        </p>
      </div>
    ),
  },
  {
    title:
      'Comment téléconsulter une sexologue sur Mia ?',
    body: (
      <div>
        <p>
          Avant la téléconsultation, il est d’abord nécessaire de remplir un questionnaire
          médical précis, qui permettra au sexologue de connaître d’éventuels antécédents
          médicaux. Ensuite, la patiente peut choisir le créneau horaire qui lui convient
          le mieux, ainsi que le jour et la date.
        </p>
        <p>
          3 modes de téléconsultation s’offrent alors :
          <p className="ml-4 mt-3">
            • Par
            <b> vidéo ;</b>
            <br />
            <b>• Messages sécurisés ;</b>
            <br />
            • Ou par
            <b> téléphone.</b>
          </p>
        </p>
        <p>
          Une fois le RDV confirmé et les informations personnelles remplies, le prix de la
          téléconsultation doit être préréglé sur la plateforme.
        </p>
        <p>
          Lorsque la consultation est terminée, le dossier patient est accessible à tout moment
          dans l’espace personnel, ainsi que le compte-rendu de la consultation et les
          recommandations d’accompagnement.
        </p>
      </div>
    ),
  },
  {
    title:
      'Quels sont les avantages de Mia.co ?',
    body: (
      <div className="ml-4">
        <p>
          • Une équipe de praticiennes (sexologues, sages-femmes, thérapeutes de couple)
          spécialistes de la sexualité féminine et spécialement formées à la pratique de la
          téléconsultation ;
        </p>
        <p>
          • Un comité scientifique composé de médecins sexologues, de professeures d’université
          en sexologie et de membres dirigeants d’associations de sexologues ;
        </p>
        <p>
          • Une consultation de 30 min à 45 euros (les prix en cabinet varient de 80 à 100 euros) ;
        </p>
        <p>
          • La livraison de produits d’accompagnement en 24 ou 48H.
        </p>
      </div>
    ),
  },
];
