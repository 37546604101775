import React, { useState } from 'react';
import CharlesMeta from 'components/common/meta/CharlesMeta';

import Container from 'react-bootstrap/Container';
import { Layout } from 'components/common';
import TextSectionList from 'components/common/TextSectionList';
import Section from 'components/common/Section';
import TestimoniesSection from 'components/common/TestimoniesSection';
import Image from 'components/common/utils/Image';

import StyledLink from 'components/common/utils/StyledLink';

import VideoPL from '../common/VideoPL';

import { sections, testimonies } from './data';

import './styles.sass';

import HowItWorksSection from '../HowItWorksSection';
import Collapse from 'react-bootstrap/Collapse';
import Hush from 'images/icons/hush.svg';
import Medal from 'images/icons/medal.svg';
import PharmaCross from 'images/icons/pharma-cross-with-heart.svg';
import TherapistCarousel from '../common/TherapistsCarousel';
import { libido } from '../common/data/doctors';

const IndexPage = () => {
  const [open, setOpen] = useState(false);
  return (
    <Layout
      footerCtaPath="/consulter-baisse-de-libido/"
      showLinksInFooter={false}
      backgroundBeforeFooter="background-color-pale-yellow"
    >
      <CharlesMeta
        data={{
          title: 'Baisse de désir ou absence de libido ? Consultez en ligne une sexologue',
          description:
            "Perte de libido, absence de désir, manque d'envie ? Consultez une sexologue en ligne sur Mia.co par vidéoconférence, téléphone ou messagerie, 7 jours sur 7.",
        }}
      />
      <div className="text-left landing-page">
        <Section className="text-white d-flex flex-column background-color-pale-yellow home-top-section patho-top-section libido-top-section m-lg-0">
          <h1 className="h1 text-center main-title-patho align-self-center d-lg-none mb-1">
            Votre désir vous échappe&nbsp;? Et si vous en parliez à une sexologue&nbsp;?
          </h1>
          <div className="desktop-home-top-div desktop-patho-top-div d-none d-lg-flex">
            <div className="home-top-left">
              <div>
                <h1 className="h1-patho-top-left">
                  Votre désir vous échappe&nbsp;?
                  <br />
                  Et si vous en parliez à une sexologue&nbsp;?
                </h1>
                <div className="d-flex cta-div">
                  <StyledLink
                    variant="orange"
                    path="/consulter-baisse-de-libido/"
                    className=""
                    margin
                  >
                    CONSULTER
                  </StyledLink>
                </div>
              </div>
            </div>
            <div className="d-flex flex-column desktop-videoPL justify-content-lg-center align-items-lg-end">
              <VideoPL className="" />
              <span className="video-patho-text">
                Je suis Camille Bataillon, la sexologue qui a créé ce protocole de
                téléconsultation.
              </span>
            </div>
          </div>
          <div className="info-div info-div-patho">
            <div className="d-flex justify-content-center d-lg-none">
              <div className="d-flex cta-div">
                <StyledLink
                  variant="orange"
                  path="/consulter-baisse-de-libido/"
                  className=""
                >
                  CONSULTER
                </StyledLink>
              </div>
            </div>
            <div className="info-list info-list-patho">
              <div className="text-left">
                <div className="img-wrapper"><Hush /></div>
                <span className="hush-span">CONFIDENTIEL & SÉCURISÉ</span>
              </div>
              <div>
                <div className="img-wrapper"><Medal /></div>
                <span className="medal-span">SEXOLOGUES DIPLÔMÉES</span>
              </div>
              <div>
                <div className="img-wrapper"><PharmaCross /></div>
                <span className="pharmacross-span">SUIVI & ASSISTANCE ILLIMITÉS</span>
              </div>
            </div>
          </div>
          <Container className="d-flex flex-column align-items-center video-patho-container d-lg-none">
            <VideoPL />
            <span className="video-patho-text">
              Je suis Camille Bataillon, la sexologue qui a créé ce protocole de téléconsultation.
            </span>
          </Container>
        </Section>
        <HowItWorksSection />
        <Section className="d-flex flex-column flex-lg-row patho-explanation-section justify-content-lg-center align-items-lg-center">
          <div className="d-none d-lg-flex patho-explanation-left">
            <Image filename="women-pb-libido.png" className="patho-img explainations-img-purple" />
          </div>
          <div className="d-flex flex-column patho-explanation-right">
            <h2 className="section-title h2-title patho-title">
              35 à 52% des femmes sont concernées par le manque ou la baisse de libido
            </h2>
            <Container className="patho-container">
              <div className="patho-paragraph">
                <p>
                  Il est difficile de définir clairement et de façon universelle une baisse de
                  libido chez les femmes ; précisément parce que toutes les femmes n’ont pas la
                  même libido, ni les mêmes attentes de la sexualité.
                </p>
                <p>
                  De nos jours, les sexologues sont sensibles à deux critères : premièrement, la
                  notion de souffrance, qui peut accompagner la plainte d’une patiente, et
                  deuxièmement, l’importance de prendre en considération ce ressenti.
                </p>
                <p>
                  Et comme le soulignent les sexologues, avant de vouloir soigner une baisse de
                  libido, il faut en identifier les causes, et s'intéresser à tout ce qui peut la
                  perturber.
                </p>
                <p className="d-none d-lg-block">
                  De nombreuses conditions particulières peuvent augmenter la fréquence des troubles
                  sexuels féminins (ménopause, endométriose, post-partum, maladies neurologiques,
                  traitements des maladies chroniques, etc.).
                </p>
                <p className="d-none d-lg-block">
                  Forte de l’expérience psychologique et médicale de ses sexologues, l’équipe de
                  Mia.co s’appuie sur les dernières études scientifiques afin d'accompagner ses
                  patientes, et de leur proposer des méthodes efficaces via la téléconsultation.
                </p>
                <Collapse in={open} className=" d-lg-none">
                  <div id="example-collapse-text">
                    <p>
                      De nombreuses conditions particulières peuvent augmenter la fréquence des
                      troubles sexuels féminins (ménopause, endométriose, post-partum, maladies
                      neurologiques, traitements des maladies chroniques, etc.).
                    </p>
                    <p>
                      Forte de l’expérience psychologique et médicale de ses sexologues, l’équipe de
                      Mia.co s’appuie sur les dernières études scientifiques afin d'accompagner ses
                      patientes, et de leur proposer des méthodes efficaces via la téléconsultation.
                    </p>
                  </div>
                </Collapse>
                <div className="d-flex flex-column d-lg-none">
                  {open === false ? (
                    <span
                      onClick={() => setOpen(!open)}
                      aria-controls="example-collapse-text"
                      aria-expanded={open}
                      className="read-more align-self-end"
                    >
                      Lire la suite
                    </span>
                  ) : (
                    <span
                      onClick={() => setOpen(!open)}
                      aria-controls="example-collapse-text"
                      aria-expanded={open}
                      className="read-more align-self-end"
                    >
                      Voir moins
                    </span>
                  )}
                </div>
              </div>
            </Container>
            <div className="d-flex cta-div justify-content-center justify-content-lg-start">
              <StyledLink
                variant="orange"
                path="/consulter-baisse-de-libido/"
                className=""
                margin
              >
                CONSULTER
              </StyledLink>
            </div>
          </div>
        </Section>
        <Section className="d-flex flex-column therapist-carousel-section">
          <div className="d-flex flex-column therapist-div-wrapper">
            <h2 className="h2-title text-white text-center">DES EXPERTES POUR VOUS ACCOMPAGNER</h2>
            <TherapistCarousel data={libido} />
          </div>
        </Section>
        <TestimoniesSection testimonies={testimonies} path="/consulter-baisse-de-libido" />
        <Section className="d-flex flex-column align-items-center faq-section">
          <Container className="d-flex flex-column align-items-center">
            <h3 className="section-title text-center">
              FAQ
            </h3>
            <TextSectionList sections={sections} />
          </Container>
        </Section>
      </div>
    </Layout>
  );
};

export default IndexPage;
